import { Container } from 'react-bootstrap';
// import React, { useEffect, useState, useContext } from 'react';
// import React, { useEffect, useContext } from 'react';
import React, { useEffect } from 'react';

// import { useAuth0 } from '@auth0/auth0-react';
// import { AppUserContext } from './App';

// import Header from './Header';
// import HomeDefault from './HomeDefault';
import You from './components/shares/You';
import Who from './components/shares/Who';
import Why from './components/shares/Why';
import What from './components/shares/What';
import Article from './components/shares/Article';
import How from './components/shares/How';
import Preview from './components/shares/Preview';

import Shares from './components/Shares';
import Impressions from './components/Impressions';
import Prospects from './components/Prospects';
import ErrorPage from './error-page';
  
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    // { path: "/",  element: <HomeDefault />, errorElement: <ErrorPage /> },
    { path: "/",  element: <You/>, errorElement: <ErrorPage /> },
    { path: "/new",  element: <You/>, errorElement: <ErrorPage /> },
    { path: "/who",  element: <Who/>, errorElement: <ErrorPage /> },
    { path: "/why",  element: <Why/>, errorElement: <ErrorPage /> },
    { path: "/what",  element: <What/>, errorElement: <ErrorPage /> },
    { path: "/article",  element: <Article/>, errorElement: <ErrorPage /> },
    { path: "/how",  element: <How/>, errorElement: <ErrorPage /> },
    { path: "/preview",  element: <Preview/>, errorElement: <ErrorPage /> },
    
    { path: "/shares",  element: <Shares/>, errorElement: <ErrorPage /> },
    { path: "/impressions",  element: <Impressions />, errorElement: <ErrorPage /> },
    { path: "/prospects",  element: <Prospects />, errorElement: <ErrorPage /> },
  ]);

export default function Home() {

    // const {appUser, setAppUser} = useContext(AppUserContext);
    // let appUser = useContext(AppUserContext);
    // function setAppUser(au) {}
    // const [isSettingUser, setIsSettingUser] = useState(false);

    // const { user, getAccessTokenSilently } = useAuth0();

    // const authToken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InhsT1dzenVJWHhWbi1kZWlTdWV3dyJ9.eyJpc3MiOiJodHRwczovL2NvbnRleHR1YWwtcWEudXMuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDY1MTVkOWQ4MDdlMTIzYWViMWIyZWQ4NSIsImF1ZCI6WyJodHRwczovL2NvbnRleHR1YWwtcWEvYXBpIiwiaHR0cHM6Ly9jb250ZXh0dWFsLXFhLnVzLmF1dGgwLmNvbS91c2VyaW5mbyJdLCJpYXQiOjE2OTY0Njg4MDksImV4cCI6MTY5NjU1NTIwOSwiYXpwIjoiOWQ5VWFxT3JUaE9BQjFmZlpJRWVJaGdQd2loRjJPS1YiLCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIn0.UW7DC--0wTtwuuGD1SEwx56ykoLXCio8x0t00bXjmgpFaKMe-8a2AydlPD7TXbtVdDCAFcSNaAXrIEwEcOLQTrTAzyYER7CCxKNRSo-QVcFccCC7HT5YXr8bIFC7OpWTmvAqcTmxWeDLWNquggzuQLKyVjCKsagq78BIfoJ9Si-wd3tknve2QnKFcS2SWmXDqT2QM1dL8AnZgs-k1k_kCrEQWRsXIZcXKoUUFQj62GVwTvCpo5kqKtMDSTTXPz-m_Op0IdVvyJVZn9LH2omVmHDBew2b_AswgutbiSR4-rh3SppgT4amMFPiSNKTL6-RwdguqP5mYI8bW7B9Hwa-Pw";
    // const headers = {
    //     'x-org-id': 'relat-ai', 
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' + authToken
    // };

    // const apiRoot = "https://native-object.qa.contextual.io/api/v1";
    // const appUserPath = apiRoot + "/app-user";
    // const urlGetByAuth0id = appUserPath + "/auth0id/" + user.sub;

    const findUserData = async () => {

        // if(appUser.id != null || isSettingUser) {
        // if(appUser.id != null) {
        //     return;
        // }
        // // setIsSettingUser(true);

        // // let audience = "7191iMmXc5ub1ep7LwmfjxdKrScDFdlI";
        // let audience = "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/";

        // const accessToken = await getAccessTokenSilently({
        //     authorizationParams: {
        //       audience:audience,
        //       scope: "read:current_user",
        //     },
        //   });

        //   console.log('at: ' + accessToken);

        //   headers.Authorization = 'Bearer ' + accessToken;

        //   let getUserByAuth0id = "https://frontend-api-endpoints-agent.service.relat-ai.my.qa.contextual.io/api/user";

        // fetch(urlGetByAuth0id, {headers: headers})
        // fetch(getUserByAuth0id, {headers: headers})
        // .then(response => {
        //     if (response.ok) {
        //         return response.json();
        //     }
        //     throw new Error('Something went wrong');
        //     })
        // .then(data => {
        //   setAppUser(data)
        // })
        // .catch((error) => {
        //     console.log('user not found ' + user.sub);
        //     console.log(error);
        //     const appUserBody = {
        //         auth0id: user.sub, 
        //         username: user.email, 
        //         email: user.email, 
        //         nickname: user.email,
        //         dateLastLogin: new Date().toISOString().substring(0,19) 
        //     };
        //     const createUserOpts = {
        //         headers: headers, 
        //         method: "POST", 
        //         body: JSON.stringify(appUserBody)
        //     }
        //     fetch(appUserPath, createUserOpts)
        //     .then(response => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //         })
        //     .then(data => {
        //       setAppUser(data)
        //     })
        //     .catch((error) => {
        //         console.log('error creating user ' + user.sub);
        //         console.log(error);
        //         setAppUser(null);
        //     });
        // });
        // setIsSettingUser(false);
    };

    useEffect(() => {
      findUserData();
    });

    
    return (
        <Container>
            <RouterProvider router={router} />
        </Container>
    );
}




