




import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';



function What() {

    const { getAccessTokenSilently } = useAuth0();
    const [loadingData, setLoadingData] = useState(true);
    const [message, setMessage] = useState("");
    const [selectedTopic, setSelectedTopic] = useState("");
    const [topicsList, setTopicsList] = useState([]);

    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const shareId = params.get('share');

    const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
    const sharePath = apiRoot + "/share";
    let topicsPath = sharePath + "/" + shareId + "/topic"

    let navigate = useNavigate();
    let back = function() {
        navigate(-1);        
    }

    let handleSubmit = async function(e) {
        e.preventDefault();

        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        let shareUpdate = {
            topicId: selectedTopic
        }
        
        fetch(topicsPath, {headers: headers, method:"POST", body:JSON.stringify(shareUpdate)})
        .then(async response => {
            if (response.ok) {
                navigate(`/article?share=${shareId}`);
            } else {
                throw new Error('Error selecting topic: ' + response.status);
            }
        })
        .catch((e) => {
            console.log("error selecting topic")
            setMessage(e.message);
        });

    };

    let loadTopics = async function() {
          const oauthAccessToken = await getAccessTokenSilently({
              authorizationParams: {
                  redirect_uri: window.location.origin,
                  audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
              },
          });
        
          const headers = {
              'x-org-id': 'relat-ai', 
              "Content-Type": "application/json",
              Authorization: `Bearer ${oauthAccessToken}`
          };


          fetch(topicsPath, {headers: headers})
          .then(async response => {
              const apiTopics = await response.json();
              if(apiTopics.items && apiTopics.items.length > 0) {
                  setTopicsList(apiTopics.items);
              } else {
                  throw new Error("Error getting topics")
              }
              setLoadingData(false);
          })
          .catch((e) => {
              console.log("error getting topics")
              setMessage(e.message);
          });
  
      };

    useEffect(() => {
        if(loadingData) {
          loadTopics();
        }
      });



    return (
    <Container>
    <Header />
    <br />
    <h1>What do you want to talk about?</h1>
    <div>
    <Form onSubmit={handleSubmit}>
    <div className="message">{message ? <p>{message}</p> : null}</div>
            <br />


    <ButtonGroup style={{flexWrap: 'wrap'}}>
        {topicsList.map((topic, idx) => (
          <ToggleButton
            key={idx}
            id={`topic-${idx}`}
            type="radio"
            variant="light"
            name="topic"
            value={topic.id}
            checked={selectedTopic === topic.id}
            onChange={(e) => setSelectedTopic(e.currentTarget.value)}
            className="me-3 mt-2 text-nowrap"
          >
            {topic.name}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <Button variant="secondary" type="button" onClick={back}>Back</Button> 
        &nbsp;           
        <Button variant="primary" type="submit" disabled={selectedTopic===""}>Next</Button>
    </Form>
    </div>

    </Container>
    );
}

export default What;
