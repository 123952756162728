

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Button from 'react-bootstrap/Button';

function ShowAssistant({ assistants, createNew, next }) {
    if(assistants && assistants.length > 0) {
        let assistant = assistants[0];
        return (
            <div>
                <div>
                    <h1>{assistant.name}</h1>
                    <p>{assistant.description}</p>
                </div>
                <Button onClick={next}>Next</Button>
            </div>
        );
    } else {
        return (<Button onClick={createNew}>Create a new assistant</Button>);
    }
}


function You() {

  const { getAccessTokenSilently } = useAuth0();

  const [loadingData, setLoadingData] = useState(true);
  const [assistantsList, setAssistantsList] = useState([]);
  const [shouldCreateAssistant, setShouldCreateAssistant] = useState(false);
  const [message, setMessage] = useState("");
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
  const assistantsPath = apiRoot + "/ai-assistant";

  
  let loadAssistants = async function() {
        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };
        fetch(assistantsPath, {headers: headers})
        .then(async response => {
            const apiAssistants = await response.json();
            if(apiAssistants.items && apiAssistants.items.length > 0) {
                setAssistantsList(apiAssistants.items);
            } else {
                setShouldCreateAssistant(true);
            }
            setLoadingData(false);
        })
        .catch((e) => {
            console.log("error getting assistants")
            setMessage(e.message);
        });

    };
  
      useEffect(() => {
        if(loadingData) {
          loadAssistants();
        }
      });

    let handleSubmit = async (e) => {
        e.preventDefault();

        let newAssistant = {
            name: newName, 
            description: newDescription
        }

        // console.log(newAssistant);

        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        fetch(assistantsPath, {headers: headers, method:"POST", body:JSON.stringify(newAssistant)})
        .then(async response => {
            if (response.ok) {
                let apiAssistant = await response.json();
                setAssistantsList([apiAssistant].concat(assistantsList));
                setShouldCreateAssistant(false);
            } else {
                throw new Error('Error creating assistant: ' + response.status);
            }
        })
        .catch((e) => {
            console.log("error creating assistants")
            setMessage(e.message);
        });

    }

    
    let navigate = useNavigate();
    let next = function() {
        
        if(assistantsList && assistantsList.length > 0)
        {
            let assistant = assistantsList[0];
            let assistantId = assistant.id;
    
            navigate(`/who?assistant=${assistantId}`)

        }
    };

    return (
      <Container>
        <Header />
        <br />
        <h1>Welcome back, you!</h1>
        {(!shouldCreateAssistant) && <ShowAssistant assistants={assistantsList} createNew={() => setShouldCreateAssistant(true)} next={() => next()} />} 
        
        {shouldCreateAssistant &&  <div>
            <Form onSubmit={handleSubmit}>
            <h1>Create a new Assistant</h1>
            <br />
            <div className="message">{message ? <p>{message}</p> : null}</div>
            
            <Form.Group as={Row} className="mb-3" controlId="formCreateAssistantName">
                Name: 
                <Form.Control onChange={(e) => setNewName(e.target.value)} />
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCreateShareDescription">
                Description: 
                <Form.Control type="text" defaultValue="" onChange={(e) => setNewDescription(e.target.value)} />
            </Form.Group>
            
            <Button variant="primary" type="submit">Next</Button>
        </Form>
        </div> }
      </Container>
    );

    
}

export default You;


