

import React from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';

// import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';
import Spinner from 'react-bootstrap/Spinner';

function Preview() {




    return (
        <Container>
        <Header />
        <br />
        <h1>Great! Sit tight while we get that for you.</h1>
        <div>
        <Spinner animation="border"  /> 
        </div>
    
        </Container>
        );
    }
    
    export default Preview;
    