import {LinkContainer} from 'react-router-bootstrap'
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useAuth0 } from '@auth0/auth0-react';
// import { useContext } from 'react';
// import { AppUserContext } from './App';

export default function Header() {

    const { user, logout } = useAuth0();
    // const {appUser} = useContext(AppUserContext);


    return (
        <Navbar bg="primary" data-bs-theme="dark">
            <LinkContainer to="/"><Navbar.Brand href="/">Relatus.ai</Navbar.Brand></LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <LinkContainer to="/new"><Nav.Link>New</Nav.Link></LinkContainer>
                    <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
                    <LinkContainer to="/shares"><Nav.Link>Shares</Nav.Link></LinkContainer>
                    <LinkContainer to="/impressions"><Nav.Link>Impressions</Nav.Link></LinkContainer>
                    <LinkContainer to="/prospects"><Nav.Link>Prospects</Nav.Link></LinkContainer>
                </Nav>
            </Navbar.Collapse>

            <Navbar.Text>
            <div>Hello {user.name}</div>
            <div>{user.sub}</div>
            {/* <div>appuser: {appUser.id}</div> */}
            <Button variant="secondary" size="sm" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                Log out
            </Button></Navbar.Text>
        </Navbar>
    );
}