import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    
    <Auth0Provider
        domain="dev-0ictgiggt1fensei.us.auth0.com"
        clientId="7191iMmXc5ub1ep7LwmfjxdKrScDFdlI"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
          // , 
          // scope: "read:current_user"
        }} 
    >

      <App /> 

    </Auth0Provider>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.min.js" integrity="sha384-BBtl+eGJRgqQAUMxJ7pMwbEyER4l1g+O15P+16Ep7Q9Q+zqX6gSbd85u4mG4QzX+" crossorigin="anonymous"></script>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
