


import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';


function Who() {

    const { getAccessTokenSilently } = useAuth0();

    const [loadingData, setLoadingData] = useState(true);
    const [message, setMessage] = useState("");
    const [audiencesList, setAudiencesList] = useState([]);
    const [role, setRole] = useState("");
    const [industry, setIndustry] = useState("");
    const [url, setUrl] = useState("");
    const [selectedAudienceId, setSelectedAudienceId] = useState("");
    const [selectedAudienceName, setSelectedAudienceName] = useState("");
    const [isCreatingNewAudience, setIsCreatingNewAudience] = useState(false);
    const [isEditing, setIsEditing] = useState(false);


    const location = useLocation();
    const assistantId = new URLSearchParams(location.search).get('assistant');

    const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
    const audiencePath = apiRoot + "/audience";
    const sharePath = apiRoot + "/share"
  
    let navigate = useNavigate();
    let back = function() {
        navigate(-1);        
    }

    let handleSubmit = async function(e) {
        e.preventDefault();

        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        let share = {
            assistantId: assistantId
        }

        if(!isCreatingNewAudience) {
            share.audienceId = selectedAudienceId;
        }


        if(isCreatingNewAudience || isEditing) {
            let audience = {
                role: role, 
                industry: industry, 
                url: url
            };

            let method = "POST";
            let path = audiencePath;
            if(isEditing) {
                method = "PUT";
                path = path + "/" + selectedAudienceId;
            }

            let apiAudience = await fetch(path, {headers: headers, method:method, body:JSON.stringify(audience)})
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error creating audience: ' + response.status);
                }
            })
            .catch((e) => {
                console.log("error creating audience")
                setMessage(e.message);
            });

            share.audienceId = apiAudience.id;
        }

        fetch(sharePath, {headers: headers, method:"POST", body:JSON.stringify(share)})
        .then(async response => {
            if (response.ok) {
                let apiShare = await response.json();
                navigate(`/why?share=${apiShare.id}`);
            } else {
                throw new Error('Error creating share: ' + response.status);
            }
        })
        .catch((e) => {
            console.log("error creating share")
            setMessage(e.message);
        });

    };

    const loadAudiences = async function() {
        console.log('loading audiences')
        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };
        fetch(audiencePath, {headers: headers})
        .then(async response => {
            const apiAudiences = await response.json();
            if(apiAudiences.items && apiAudiences.items.length > 0) {
                setAudiencesList(apiAudiences.items);
            } else {
                throw new Error("Error getting audience");
            }
            setLoadingData(false);
        })
        .catch((e) => {
            console.log("error getting audience")
            setMessage(e.message);
        });
    }

    useEffect(() => {
        if(loadingData) {
          loadAudiences();
        }
      });

      const handleSelect = (key, event) => {
          if(key === "create") {
              setIsCreatingNewAudience(true)
              setSelectedAudienceId("");
              setSelectedAudienceName("");
              setRole("");
              setIndustry("");
              setUrl("");
          } else {
              setIsCreatingNewAudience(false)
              let selectedAudience = audiencesList.find((element) => element.id === key);
              if(!selectedAudience) {
                  return;
              }
              setSelectedAudienceId(key);
              setSelectedAudienceName(selectedAudience.role);
              setRole(selectedAudience.role);
              setIndustry(selectedAudience.industry);
              setUrl(selectedAudience.url);
          }
          event.target.active = true;

      };

    return (
    <Container>
    <Header />
    <br />
    <h1>Who are you messaging today?</h1>
    <div>
    <Form onSubmit={handleSubmit}>
            <div className="message">{message ? <p>{message}</p> : null}</div>
            <br />
            
            <Form.Group as={Row} className="mb-3" controlId="formCreateRole">
                Role or Group <br /> 
                <div className="mb-3">
                {!isEditing && 
                <DropdownButton
                    key="audience"
                    id="audience"
                    variant="secondary"
                    title={loadingData ? <Spinner animation="border" size="sm" /> : selectedAudienceName ? selectedAudienceName : "Create a new Audience"}
                    onSelect={handleSelect}
                >

                    {loadingData && <Dropdown.Item key="loading" eventKey="loading">Loading ... <Spinner animation="border" size="sm"/></Dropdown.Item>}

                    {audiencesList.map((audience, idx) => (
                        <Dropdown.Item key={audience.id} eventKey={audience.id}>{audience.role}</Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item key="create" eventKey="create">Create a new Audience</Dropdown.Item>
                </DropdownButton>
                }
                {!isEditing && !isCreatingNewAudience && <Button variant="link" size="sm" onClick={(e) => setIsEditing(true)}>edit</Button>}
                {(isCreatingNewAudience || isEditing) && 
                <div>
                    <Form.Control value={role} onChange={(e) => setRole(e.target.value)} />
                    <p className="sm-3">e.g. "James, Head of Product" or "Marketing Team"
                    {isEditing && !isCreatingNewAudience && <Button variant="link" size="sm" onClick={(e) => setIsEditing(false)}>cancel editing audience</Button>}
                    </p>
                    <p className="sm-2">-------- plus, at least one of the following --------</p>
                </div>
                }
                </div>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCreateIndustry">
                Inudstry or Specialty
                <Form.Control type="text" value={industry} disabled={!isCreatingNewAudience && !isEditing} onChange={(e) => setIndustry(e.target.value)} />
                <p className="sm-3">It's ok to be specific! E.g. "Consumer Finance" or "Cryptocurrency Exchange"</p>
            </Form.Group>
            
            <Form.Group as={Row} className="mb-3" controlId="formCreateSite">
                Business Site
                <Form.Control type="text" value={url} disabled={!isCreatingNewAudience && !isEditing} onChange={(e) => setUrl(e.target.value)} />
            </Form.Group>

            <Button variant="secondary" type="button" onClick={back}>Back</Button> 
            &nbsp;           
            <Button variant="primary" type="submit">Next</Button>
        </Form>

    </div>

    </Container>
    );
}

export default Who;
