



import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';



function Why() {

    const { getAccessTokenSilently } = useAuth0();

    const [message, setMessage] = useState("");
    const [goalsList, setGoalsList] = useState([]);
    const [selectedGoalId, setSelectedGoalId] = useState("");
    const [selectedGoalName, setSelectedGoalName] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [isCreatingNewGoal, setIsCreatingNewGoal] = useState(false);
    const [isEditingGoal, setIsEditingGoal] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const shareId = params.get('share');

    const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
    const goalPath = apiRoot + "/goal";
    const sharePath = apiRoot + "/share";
  
    const loadGoals = async function() {
        console.log('loading goals')
        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        fetch(goalPath, {headers: headers})
        .then(async response => {
            const apiGoals = await response.json();
            if(apiGoals.items && apiGoals.items.length > 0) {
                setGoalsList(apiGoals.items);
                console.log(`got goals: ${apiGoals.items.length}`)
                console.log(`got goals: ${JSON.stringify(apiGoals.items)}`)
                if(apiGoals.items.length > 0) {

                    console.log(`goals[0]: ${JSON.stringify(goalsList[0])}`)
                    console.log(`apiGoals.items[0]: ${JSON.stringify(apiGoals.items[0])}`)

                    let firstGoal = apiGoals.items[0];
                    setIsCreatingNewGoal(false)
                    setIsEditingGoal(false)
                    setSelectedGoalName(firstGoal.name)
                    setSelectedGoalId(firstGoal.id)
                    setName(firstGoal.name)
                    setDescription(firstGoal.description)
                } else {
                    setIsCreatingNewGoal(true)
                    setIsEditingGoal(false)
                    setSelectedGoalId("")
                    setSelectedGoalName("")
                    setName("")
                    setDescription("")
                }
            } else {
                throw new Error("Error getting goals");
            }
            setLoadingData(false);
        })
        .catch((e) => {
            console.log("error getting goals")
            setMessage(e.message);
        });
    }


    let navigate = useNavigate();
    let back = function() {
        navigate(-1);        
    }

    let handleSubmit = async function(e) {
        e.preventDefault();

        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
        });
      
        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        let shareUpdate = {};
        if(!isCreatingNewGoal) {
            shareUpdate.goalId = selectedGoalId;
        }

        if(isCreatingNewGoal || isEditingGoal) {
            let goal = {
                name: name, 
                description: description
            };
    
            let apiGoal = await fetch(goalPath, {headers: headers, method:"POST", body:JSON.stringify(goal)})
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error creating goal: ' + response.status);
                }
            })
            .catch((e) => {
                console.log("error creating goal")
                setMessage(e.message);
            });

            shareUpdate.goalId = apiGoal.id;
        }

        let shareUpdatePath = sharePath + "/" + shareId + "/goal";
        fetch(shareUpdatePath, {headers: headers, method:"POST", body:JSON.stringify(shareUpdate)})
        .then(async response => {
            if (response.ok) {
                navigate(`/what?share=${shareId}`);
            } else {
                throw new Error('Error creating goal: ' + response.status);
            }
        })
        .catch((e) => {
            console.log("error creating goal")
            setMessage(e.message);
        });

    };

    const handleSelect = (key, event) => {
        if(key === "create") {
            setIsCreatingNewGoal(true)
            setSelectedGoalId("");
            setSelectedGoalName("");
            setName("");
            setDescription("");
        } else {
            setIsCreatingNewGoal(false)
            let selectedGoal = goalsList.find((element) => element.id === key);
            if(!selectedGoal) {
                return;
            }
            setSelectedGoalId(key);
            setSelectedGoalName(selectedGoal.name);
            setName(selectedGoal.name);
            setDescription(selectedGoal.description);
        }
        event.target.active = true;
    };

    useEffect(() => {
        if(loadingData) {
          loadGoals();
        }
      });

    return (
    <Container>
    <Header />
    <br />
    <h1>Why are you reaching out?</h1>
    <div>
    <Form onSubmit={handleSubmit}>
            <div className="message">{message ? <p>{message}</p> : null}</div>
            <br />
            
            <Form.Group as={Row} className="mb-3" controlId="formCreateGoal">
                Goal <br /> 
                <div className="mb-3">
                {!isEditingGoal && 
                <DropdownButton
                    key="goal"
                    id="goal"
                    variant="secondary"
                    title={loadingData ? <Spinner animation="border" size="sm" /> : selectedGoalName ? selectedGoalName : "Create a new Goal"}
                    onSelect={handleSelect}
                >

                    {loadingData && <Dropdown.Item key="loading" eventKey="loading">Loading ... <Spinner animation="border" size="sm"/></Dropdown.Item>}

                    {goalsList.map((goal, idx) => (
                        <Dropdown.Item key={goal.id} eventKey={goal.id}>{goal.name}</Dropdown.Item>
                    ))}
                    <Dropdown.Divider />
                    <Dropdown.Item key="create" eventKey="create">Create a new Goal</Dropdown.Item>
                </DropdownButton>
                }
                {!isEditingGoal && !isCreatingNewGoal && <Button variant="link" size="sm" onClick={(e) => setIsEditingGoal(true)}>edit</Button>}
                {(isCreatingNewGoal || isEditingGoal) && 
                <div>
                <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                <p className="sm-3">E.g. "General Networking" or "Sales Outreach"</p>
                {isEditingGoal && !isCreatingNewGoal && <Button variant="link" size="sm" onClick={(e) => setIsEditingGoal(false)}>cancel editing goal</Button>}
                </div>
                }
                </div>

            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formCreateGoalDescription">
                Description
                <Form.Control type="text" value={description} disabled={!isCreatingNewGoal && !isEditingGoal} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            
            <Button variant="secondary" type="button" onClick={back}>Back</Button> 
            &nbsp;           
            <Button variant="primary" type="submit">Next</Button>
        </Form>

    </div>

    </Container>
    );
}

export default Why;
