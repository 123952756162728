
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Container from 'react-bootstrap/Container';
import { Table } from 'react-bootstrap';
import Header from '../Header';


function ImpressionRow({impression}) {
  return (
    <tr>
      <td border="1px">{impression.subject}</td>
      <td border="1px">{impression.prospect}</td>
      <td border="1px">{impression.link}</td>
      <td border="1px">{impression.impressions}</td>
    </tr>
  );
}

function ListImpressions({ impressions }) {
    const rows = [];
    
    impressions.forEach((impression) => {
        rows.push(
        <ImpressionRow impression={impression} key={impression.id} />
        );
    });
    
    return (
        <Table striped bordered>
          <thead><tr><th>Title</th><th>Prospect</th><th>Url</th><th>Impression count</th></tr></thead>
        <tbody>{rows}</tbody>
        </Table>
    );
}

function Impressions() {

  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>; // TODO: redirect? 
  }

  if (isAuthenticated) {

    const impressionsList = [
        {id: "a", subject: "3D Mapping Earth from Space Satellite Clusters", prospect: "Tech Exec", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", impressions:3}, 
        {id: "b", subject: "3D Mapping Earth from Space Satellite Clusters", prospect: "George Haroldson", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", impressions:1}, 
        {id: "c", subject: "3D Mapping Earth from Space Satellite Clusters", prospect: "LinkedIn", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", impressions:0}
    ];

    return (
      <Container>
        <Header />
        <br />
        <h1>Impressions</h1>
        <ListImpressions impressions={impressionsList} />
      </Container>
    );
  } else {
    
  }
}

export default Impressions;
