

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Header from '../../Header';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';



function How() {

    const { getAccessTokenSilently } = useAuth0();
    // const [loadingData, setLoadingData] = useState(true);
    const [message, setMessage] = useState("");
    const [selectedShareType, setSelectedShareType] = useState("");


    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const shareId = params.get('share');
    
    const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
    const sharePath = apiRoot + "/share";
    const articlesPath = sharePath + "/" + shareId + "/article";

    let navigate = useNavigate();
    let back = function() {
        navigate(-1);        
    }

    let handleSubmit = async function(e) {
        e.preventDefault();
        
        const oauthAccessToken = await getAccessTokenSilently({
            authorizationParams: {
              redirect_uri: window.location.origin,
              audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
            },
          });
          const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
          };
          
          let shareUpdate = {
            shareType: selectedShareType
          }
      
        fetch(articlesPath, {headers: headers, method:"POST", body:JSON.stringify(shareUpdate)})
        .then(async response => {
            if (response.ok) {
                navigate(`/preview?share=${shareId}`);
            } else {
                throw new Error('Error selecting topic: ' + response.status);
            }
        })
        .catch((e) => {
            console.log("error selecting topic")
            setMessage(e.message);
        });
    };

    let shareTypes = [
        {id:"LinkedIn Connection Request", name:"Connection Request", type:"LinkedIn"},
        {id:"LinkedIn InMail Message", name:"InMail Message", type:"LinkedIn"},
        {id:"Facebook Page Post", name:"Page Post", type:"Facebook"},
        {id:"Facebook Messenger Message", name:"Messenger Message", type:"Messenger"},
        {id:"Telegram Message", name:"Telegram Message", type:"Telegram"},
        {id:"Email", name:"Email", type:"Email"},
        {id:"Blog", name:"Blog", type:"Blog"},
    ];

    return (
    <Container>
    <Header />
    <br />
    <h1>Last step - pick a format! </h1>
    <div>
    <Form onSubmit={handleSubmit}>
    <div className="message">{message ? <p>{message}</p> : null}</div>
            <br />

    <ButtonGroup style={{flexWrap: 'wrap'}}>
        {shareTypes.map((share, idx) => (
          <ToggleButton
            key={idx}
            id={`share-${idx}`}
            type="radio"
            variant="light"
            name="share"
            value={share.id}
            checked={selectedShareType === share.name}
            onChange={(e) => setSelectedShareType(e.currentTarget.value)}
            className="me-3 mt-2 text-nowrap"
          >
            {share.name}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <Button variant="secondary" type="button" onClick={back}>Back</Button> 
        &nbsp;           
        <Button variant="primary" type="submit" disabled={selectedShareType===""}>Let's do it!</Button>
    </Form>
    </div>

    </Container>
    );
}

export default How;
