
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';
// import { AppUserContext } from '../App';
import { useAuth0 } from '@auth0/auth0-react';
// import { useState, useContext } from 'react';
import { useState } from 'react';

function CreateShare({newShareHandler}) {

    const { getAccessTokenSilently } = useAuth0();

    // const {appUser, setAppUser} = useContext(AppUserContext);
    // const {appUser} = useContext(AppUserContext);
    
    const [url, setUrl] = useState("");
    const [prospect, setProspect] = useState("");
    const [goal, setGoal] = useState("");
    const [additionalInstructions, setAdditionalInstructions] = useState("for a short email");
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();

        // if(appUser == null || appUser.id == null) {
        //     setMessage("Login error");
        //     return;
        // }

        try {
            const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
            const sharesPath = apiRoot + "/shares";

            const oauthAccessToken = await getAccessTokenSilently({
                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
                    // ,
                    // scope: "read:current_user"
                },
            });

            const headers = {
                'x-org-id': 'relat-ai', 
                "Content-Type": "application/json",
                Authorization: `Bearer ${oauthAccessToken}`
            };

            const summarizationDetails = {
                "description": [goal, "to", prospect, "via", url].join(" "),
                "goal": goal,
                "url": url,
                "prospect": prospect,
                "additionalInstructions": additionalInstructions, 
                "simplePrompt": "Please summarize " + url + " with the intent of " + goal  + " directed at " + prospect + " " + additionalInstructions
            };
            
            fetch(sharesPath, {headers: headers, method:"POST", body:JSON.stringify(summarizationDetails)})
            .then(async response => {
                if (response.ok) {
                    let newShare = await response.json();
                    newShareHandler(newShare);
                } else {
                    throw new Error('Error creating link: ' + response.status);
                }
            })
            .catch((error) => {
                setMessage(e.message);
            });
    
        } catch (err) {
          console.log(err);
        }
      };

    return (
    <Form onSubmit={handleSubmit}>
        <h1>Create a new Share</h1>
        <br />
        <div className="message">{message ? <p>{message}</p> : null}</div>
        
        <Form.Group as={Row} className="mb-3" controlId="formCreateShareUrl">
        {/* <Form.Label column sm="2"> */}
            Please summarize
        {/* </Form.Label> */}
        {/* <Col sm="10"> */}
            <Form.Control placeholder="http://" onChange={(e) => setUrl(e.target.value)} />
        {/* </Col> */}
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateShareGoal">
        {/* <Form.Label column sm="2"> */}
            with the intent of 
        {/* </Form.Label> */}
        {/* <Col sm="10"> */}
            <Form.Control type="text" defaultValue="" onChange={(e) => setGoal(e.target.value)} />
        {/* </Col> */}
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateShareProspect">
        {/* <Form.Label column sm="2"> */}
            directed at
        {/* </Form.Label> */}
        {/* <Col sm="10"> */}
            <Form.Control type="text" defaultValue="" onChange={(e) => setProspect(e.target.value)} />
        {/* </Col> */}
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formCreateShareAdditionalInstructions">
        {/* <Form.Label column sm="2"> */}
            
        {/* </Form.Label> */}
            <i class="fw-light">optional additional instructions: </i>
            <Form.Control type="text" value={additionalInstructions} onChange={(e) => setAdditionalInstructions(e.target.value)} />
            <br /> 
        <Col sm="15">
            <div class="fw-light">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i>some examples: </i>
                <button class="btn btn-link fw-light mx-0 px-0" onClick={(e) => setAdditionalInstructions('suitable for a LinkedIn post')}>for a LinkedIn post</button>
                &nbsp;|&nbsp;
                <button class="btn btn-link fw-light mx-0 px-0" onClick={(e) => setAdditionalInstructions('in short, punchy bullets')}>in short, punchy bullets</button>
                &nbsp;|&nbsp;
                <button class="btn btn-link fw-light mx-0 px-0" onClick={(e) => setAdditionalInstructions('in less than 200 characters')}>in less than 200 characters</button> 
                &nbsp;|&nbsp;
                <button class="btn btn-link fw-light mx-0 px-0" onClick={(e) => setAdditionalInstructions('in less than 100 words')}>in less than 100 words</button> 
                &nbsp;|&nbsp;
                <button class="btn btn-link fw-light mx-0 px-0" onClick={(e) => setAdditionalInstructions('for a short email')}>for a short email</button>
            </div>
        </Col>
        </Form.Group>
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button variant="primary" type="submit">Summarize this content for me</Button>
    </Form>
    );
}

export default CreateShare;
