
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import { Table } from 'react-bootstrap';
import Header from '../Header';
import CreateShare from './SharesCreate';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function viewSummaryButton({share, onViewContent, setModalShare}) {

  let onClickFn = function() {
    console.log(`clicking {share.id}`)
    setModalShare(share)
    onViewContent(true)
  }

  return <Button variant="primary" onClick={() => onClickFn()}>
    view
  </Button>
}

function ShareRow({share, onViewContent, setModalShare}) {
  return (
    <tr>
      <td border="1px">{share.status}</td>
      <td border="1px">{share.prospect}</td>
      <td border="1px">{share.goal}</td>
      <td border="1px">{share.mainUrl}</td>
      <td border="1px">
        {share.status === "Complete" ? viewSummaryButton({share, onViewContent, setModalShare}) : " "}
      </td>
    </tr>
  );
}

function ListShares({ shares, onViewContent, setModalShare }) {
    const rows = [];
    
    if(shares) {
      shares.forEach((share) => {
          rows.push(
          <ShareRow share={share} key={share.id} setModalShare={setModalShare} onViewContent={onViewContent} />
          );
      });
    }
    
    return (
        <Table striped bordered>
        <thead>
          <tr>
            <th>Status</th>
            <th>Prospect</th>
            <th>Goal</th>
            <th>Url</th>
            <th>&nbsp;</th>
            </tr>
        </thead>
        <tbody>{rows}</tbody>
        </Table>
    );
}


function SummarizedResponseModal(props) {

  let share = props.modalShare

  if(!share) {
    return;
  }

  let replaceWithBr = function(content) {
    if(!content) {
      return ""
    }
    return content.replace(/\n/g, "<br />")
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>&nbsp;</h4> */}
        <p dangerouslySetInnerHTML={{__html: replaceWithBr(share.content)}} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Shares() {

  const { getAccessTokenSilently } = useAuth0();

  const [loadingData, setLoadingData] = useState(true);
  const [sharesList, setSharesList] = useState([]);
  const [isProcessingSummarization, setIsProcessingSummarization] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShare, setModalShare] = React.useState(null);

  const apiRoot = "https://frontend.service.relat-ai.my.sn.contextual.io";
  const sharesPath = apiRoot + "/shares";
  // let audience = "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/";

    let loadShares = async function() {
        const oauthAccessToken = await getAccessTokenSilently({
          authorizationParams: {
              redirect_uri: window.location.origin,
              audience: "https://dev-0ictgiggt1fensei.us.auth0.com/api/v2/"
              // ,
              // scope: "read:current_user"
          },
      });

        const headers = {
            'x-org-id': 'relat-ai', 
            "Content-Type": "application/json",
            Authorization: `Bearer ${oauthAccessToken}`
        };

        fetch(sharesPath, {headers: headers})
        .then(async response => {
            const apiShares = await response.json();
            setSharesList(apiShares.items);
            setIsProcessingSummarization(false)
            setLoadingData(false);
        });
    };
  
      useEffect(() => {
        if(loadingData) {
          loadShares();
        }

        // let interval = setInterval(async () => {
        //   if(isProcessingSummarization) {
        //     loadShares();
        //   }
        // }, 5000);
        // return () => {
        //   clearInterval(interval);
        // };
      });


    let newShareHandler = function(newShare) {
        if(newShare && !newShare.status) {
          newShare.status = "In Process"
          console.log("keep looking")
          setIsProcessingSummarization(true)
        }
        if(newShare && !newShare.mainUrl) {
          newShare.mainUrl = newShare.url
        }
        console.log("ok?")
        if(isProcessingSummarization) {
          console.log("ok now?")
        }
        if(sharesList) {
            setSharesList(shareList => [newShare, ...shareList]);
        } else {
            setSharesList(shareList => [newShare]);
        }
    };




  //   const sharesList = [
  //     {id: "a", subject: "3D Mapping Earth from Space Satellite Clusters", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", status:"Processing"}, 
  //     {id: "b", subject: "3D Mapping Earth from Space Satellite Clusters", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", status:"Ready for Review"}, 
  //     {id: "c", subject: "3D Mapping Earth from Space Satellite Clusters", link:"https://www.notboring.co/p/array-labs-3d-mapping-earth-from", status:"Live & Shared"}
  // ];


    let modalHide = function() {
      setModalShow(false)
      setModalShare(null)
    };

    return (
      <Container>
        <Header />
        <br />
        <CreateShare newShareHandler={newShareHandler} />
        <br />
        <SummarizedResponseModal
          show={modalShow}
          modalShare={modalShare}
          setModalShare={setModalShare}
          onHide={() => modalHide()}
        />
        <h1>Shares</h1>
        <ListShares shares={sharesList} onViewContent={setModalShow} setModalShare={setModalShare} />
      </Container>
    );

    
}

export default Shares;
