
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Container from 'react-bootstrap/Container';
import { Table } from 'react-bootstrap';
import Header from '../Header';


function ProspectRow({prospect}) {
  return (
    <tr>
      <td border="1px">{prospect.name}</td>
      <td border="1px">{prospect.shares}</td>
      <td border="1px">{prospect.impressions}</td>
    </tr>
  );
}

function ListProspects({ prospects }) {
    const rows = [];
    
    prospects.forEach((prospect) => {
        rows.push(
        <ProspectRow prospect={prospect} key={prospect.id} />
        );
    });
    
    return (
        <Table striped bordered>
        <thead><tr><th>Name</th><th>Shares</th><th>Impressions</th></tr></thead>
        <tbody>{rows}</tbody>
        </Table>
    );
}

function Prospects() {

  const { isLoading, isAuthenticated, error } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>; // TODO: redirect? 
  }

  if (isAuthenticated) {

    const prospectsList = [
        {id: "a", name: "Tech Exec", shares:14, impressions:32 }, 
        {id: "b", name: "George Haroldson", shares:4, impressions:3 }, 
        {id: "c", name: "LinkedIn", shares:7, impressions:17 }
    ];

    return (
      <Container>
        <Header />
        <br />
        <h1>Prospects</h1>
        <ListProspects prospects={prospectsList} />
      </Container>
    );
  } else {
    
  }
}

export default Prospects;
